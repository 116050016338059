import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/02-entstehungsgeschichte/stimmungsbarometer" />
      }
    >
      <Seo title="Das Manuskript erforschen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Das Manuskript erforschen
            </Heading>
          </Stack>
          <Paragraph>
            Die auf dem Manuskript skizzierte Hymne ›Wie wenn am Feiertage‹
            blieb Fragment. Einige Jahre später aber sah Hölderlin die Entwürfe
            noch einmal durch und fing dabei erneut zu dichten an. Aus den
            zusammenhanglos zu Papier gebrachten Wörtern wurde der Beginn eines
            neuen Gedichts: ›Hälfte des Lebens‹. Ein Manuskript hat sich dazu
            nicht erhalten. Der Verleger wird es vermutlich einbehalten haben.
          </Paragraph>
          <Paragraph>
            Auch die anderen Gedichte, die Hölderlin gemeinsam mit ›Hälfte des
            Lebens‹ in Wilmans ›Taschenbuch‹ publiziert hat, sind größtenteils
            aus Umarbeitungen früherer Entwürfe hervorgegangen.
          </Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
